import * as R from 'ramda'
import {useEffect} from 'react'

const events = ['mousedown', 'touchstart']

export default (ref, onClickOutside) => {
  const isOutside = element => !ref.current || !ref.current.contains(element)

  const onClick = event => {
    if (isOutside(event.target) && event.target.tagName !== 'LI') {
      onClickOutside()
    }
  }

  useEffect(() => {
    R.map(event => document.addEventListener(event, onClick), events)

    return () => {
      R.map(event => document.removeEventListener(event, onClick), events)
    }
  })
}
