import * as R from 'ramda'
import {bool, func, objectOf, string} from 'prop-types'
import {upperFirst} from 'lodash'
import React, {createRef} from 'react'

import useClickOutside from 'hooks/useClickOutside'
import useFetch from 'hooks/useFetch'
import useFetchObject from 'hooks/useFetchObject'

import FlexSearch from './FlexSearch/index'

const LocalSearch = ({
  country,
  data,
  navLocale,
  show,
  setFocus,
  isMultiLang,
  searchPlaceholder,
}) => {
  const rootRef = createRef()

  useClickOutside(rootRef, () => setFocus(false))

  const defaultInsightsIndex = data.localSearchInsightsCn.publicIndexURL
  const defaultInsightsStore = data.localSearchInsightsCn.publicStoreURL
  const defaultKeyplaysIndex = data.localSearchKeyplaysCn.publicIndexURL
  const defaultKeyplaysStore = data.localSearchKeyplaysCn.publicStoreURL

  const getSearchInsightsCountryKey = () => {
    if (isMultiLang) {
      return `localSearchInsights${upperFirst(country)}${upperFirst(navLocale)}`
    }

    return `localSearchInsights${upperFirst(country)}`
  }

  const getSearchKeyplaysCountryKey = () => {
    if (isMultiLang) {
      return `localSearchKeyplays${upperFirst(country)}${upperFirst(navLocale)}`
    }

    return `localSearchKeyplays${upperFirst(country)}`
  }

  const localSearchInsightsCountryKey = getSearchInsightsCountryKey()
  const localSearchKeyplaysCountryKey = getSearchKeyplaysCountryKey()

  const insightsIndexUrl = R.pathOr(
    defaultInsightsIndex,
    [localSearchInsightsCountryKey, 'publicIndexURL'],
    data,
  )
  const insightsStoreUrl = R.pathOr(
    defaultInsightsStore,
    [localSearchInsightsCountryKey, 'publicStoreURL'],
    data,
  )
  const keyplaysIndexUrl = R.pathOr(
    defaultKeyplaysIndex,
    [localSearchKeyplaysCountryKey, 'publicIndexURL'],
    data,
  )
  const keyplaysStoreUrl = R.pathOr(
    defaultKeyplaysStore,
    [localSearchKeyplaysCountryKey, 'publicStoreURL'],
    data,
  )

  const [insightsIndex] = useFetch(insightsIndexUrl)
  const [insightsStore] = useFetchObject(insightsStoreUrl)
  const [keyplaysIndex] = useFetch(keyplaysIndexUrl)
  const [keyplaysStore] = useFetchObject(keyplaysStoreUrl)

  return (
    <div ref={rootRef}>
      {insightsIndex &&
        insightsStore &&
        keyplaysIndex &&
        keyplaysStore &&
        show && (
          <FlexSearch
            country={country}
            navLocale={navLocale}
            insightsIndex={insightsIndex}
            insightsStore={insightsStore}
            keyplaysIndex={keyplaysIndex}
            keyplaysStore={keyplaysStore}
            searchPlaceholder={searchPlaceholder}
          />
        )}
    </div>
  )
}

LocalSearch.propTypes = {
  country: string,
  data: objectOf(),
  isMultiLang: bool,
  navLocale: string,
  searchPlaceholder: string,
  setFocus: func,
  show: bool,
}

LocalSearch.defaultProps = {
  country: '',
  data: {},
  isMultiLang: false,
  navLocale: '',
  searchPlaceholder: '',
  setFocus: null,
  show: false,
}

export default LocalSearch
