/* eslint-disable no-duplicate-case */
/* eslint-disable fp/no-mutating-assign */
/* eslint-disable no-plusplus */
/* eslint-disable fp/no-let */
/* eslint-disable no-cond-assign */
/* eslint-disable fp/no-loops */
import * as R from 'ramda'

export const addExternalScript = (url, callback) => {
  const script = document.createElement('script')

  script.src = url
  script.async = true
  script.onload = callback
  document.body.appendChild(script)
}

export const stringToSlug = string =>
  R.pipe(R.replace(/\s+/g, '-'), R.toLower, R.replace(/[^\w-]+/g, ''))(string)

export const slugTransformer = slug => {
  if (process.env.GATSBY_WEBSITE === 'keyrus') {
    return slug
  }
  if (process.env.GATSBY_WEBSITE === 'kls') {
    return R.replace(/-kls/, '', slug)
  }
  if (process.env.GATSBY_WEBSITE === 'keyrus-management') {
    return R.replace(/-km/, '', slug)
  }
  if (process.env.GATSBY_WEBSITE === 'keyrus-fondation') {
    return R.replace(/-kf/, '', slug)
  }

  return slug
}

export const slugSwitch = slug => {
  const isKlsSlug = /-kls/.test(slug)
  const isKmSlug = /-km/.test(slug)

  switch (true) {
    case isKlsSlug:
      return slugTransformer(slug)

    case isKmSlug:
      return slugTransformer(slug)

    default:
      return slug
  }
}

export const clearAndshrink = (content = '', dshrink) =>
  `${R.replace(/(<([^>]+)>)/gi, '', content).substring(0, dshrink)}...`

export const createHash = val => {
  const encodedVal = `/${encodeURIComponent(val)}`
  const valIsInHash = window.location.hash.includes(encodedVal)

  function removeValFromHash() {
    window.location.hash = window.location.hash.replace(`${encodedVal}`, '')
  }
  function addValToHash() {
    window.location.hash = `${
      window.location.hash.replace('/all-partners', '') + encodedVal
    }`
  }
  function setAllPartners() {
    window.location.hash = window.location.hash.replace(
      window.location.hash,
      '/all-partners',
    )
  }

  if (val === 'all-partners') {
    if (valIsInHash) {
      removeValFromHash()
    } else {
      setAllPartners()
    }
  } else if (valIsInHash) {
    removeValFromHash()
  } else {
    addValToHash()
  }
}

export const createKlsHash = val => {
  const encodedVal = `/${encodeURIComponent(val)}`
  const valIsInHash = window.location.hash.includes(encodedVal)

  function removeValFromHash() {
    window.location.hash = window.location.hash.replace(`${encodedVal}`, '')
  }
  function addValToHash() {
    window.location.hash = `${
      window.location.hash.replace('/technology', '') + encodedVal
    }`
  }
  function setAllPartners() {
    window.location.hash = window.location.hash.replace(
      window.location.hash,
      '/technology',
    )
  }

  if (val === 'technology') {
    if (valIsInHash) {
      removeValFromHash()
    } else {
      setAllPartners()
    }
  } else if (valIsInHash) {
    removeValFromHash()
  } else {
    addValToHash()
  }
}

// eslint-disable-next-line fp/no-mutating-methods
export const alphabeticalSort = arr => R.sort((a, b) => a.localeCompare(b), arr)
export const alphabeticalSortObject = arr =>
  // eslint-disable-next-line fp/no-mutating-methods
  R.sort((a, b) => a.name.localeCompare(b.name), arr)

export const formRedirection = (url, urlPrefix, locale, redirectTo) => {
  if (urlPrefix === 'worldwide') {
    return `${url}/${urlPrefix}/thank-you-${redirectTo}`
  }

  return `${url}/${urlPrefix}/${locale.toLowerCase()}/thank-you-${redirectTo}`
}

export const socialShare = (
  url,
  urlPrefix,
  locale,
  prefix,
  slug,
  hasShortPath,
) => {
  if (hasShortPath) {
    if (urlPrefix === 'worldwide') {
      return `${url}/${slug}`
    }

    return `${url}/${slug}`
  }
  if (urlPrefix === 'worldwide') {
    return `${url}/${urlPrefix}/${prefix}${slug}`
  }

  return `${url}/${urlPrefix}/${locale
    .toLowerCase()
    .slice(0, 2)}/${prefix}${slug}`
}

export const scrollTop = () => {
  window.scrollTo({top: 0, behavior: 'smooth'})
}

export const removeDuplicate = arr => Array.from(new Set(arr))

export const removeDuplicateData = arr =>
  arr.filter((v, i, a) => a.findIndex(t => t.title === v.title) === i)

export const removeDuplicateObject = arr =>
  arr.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)

export const removeDuplicateContentfulObject = arr =>
  arr.filter(
    (v, i, a) => a?.findIndex(t => t?.contentful_id === v?.contentful_id) === i,
  )

export const removeDuplicateContentfulByAttribute = (arr, attr) =>
  arr.filter((v, i, a) => a.findIndex(t => t[attr] === v[attr]) === i)

export const retrieveTags = (data, longPath) => {
  if (longPath) {
    return R.map(
      ({node}) => R.pathOr(' ', ['name', 'name'], node),
      R.filter(({node}) => node.key_play !== null, data),
    )
  }

  return R.map(
    ({node}) => R.pathOr(' ', ['name'], node),
    R.filter(({node}) => node.key_play !== null, data),
  )
}

export const oldUpdateTags = (
  val,
  useIsBrowser,
  currentTag,
  setCurrentTag,
  filterByPartnerDefaultFilter,
) => {
  if (useIsBrowser) {
    if (!currentTag.includes(val)) {
      if (val === filterByPartnerDefaultFilter) {
        setCurrentTag(tag => [...tag, 'all-partners'])
      }
      setCurrentTag(tag => [...tag, val])

      window.location.hash += `#${encodeURI(escape(val))}`
    }

    if (currentTag.includes(val)) {
      if (val === filterByPartnerDefaultFilter) {
        setCurrentTag(tag => R.filter(el => el !== 'all-partners', tag))
      }
      setCurrentTag(tag => R.filter(el => el !== val, tag))

      if (currentTag.length === 1) {
        // eslint-disable-next-line no-restricted-globals
        history.replaceState('', '', location.pathname)
      }

      window.location.hash = encodeURI(
        escape(
          R.filter(el => el !== val, currentTag)
            .toString()
            .replace(/,/g, '#'),
        ),
      )
    }
  }
}

export const updateTags = (data, useIsBrowser, currentTag, setCurrentTag) => {
  const currentTagId = R.map(node => node.id, currentTag)
  const isAllPartnersSelected = data.id === 'all-partners'
  const currentTagIsInSelectedTagsArray = currentTagId.includes(data.id)

  if (useIsBrowser) {
    if (!currentTagIsInSelectedTagsArray) {
      if (isAllPartnersSelected) {
        window.location.hash = ''
      }
      setCurrentTag(tag => [...tag, data])
      window.location.hash += `#${encodeURI(escape(data.url))}`
    }

    if (currentTagIsInSelectedTagsArray) {
      if (isAllPartnersSelected) {
        setCurrentTag(tag => R.filter(el => el !== 'all-partners', tag))
        window.location.hash = ''
      }

      const arrayWithCurrentTagRemoved = R.filter(
        el => el.id !== data.id,
        currentTag,
      )

      setCurrentTag(arrayWithCurrentTagRemoved)

      const formatTagsArrayForUrl = encodeURI(
        escape(
          R.map(e => e.url, arrayWithCurrentTagRemoved)
            .toString()
            .replace(/,/g, '#'),
        ),
      )

      window.location.hash = formatTagsArrayForUrl
    }
  }
}

export const dynamicRouting = (
  prefix,
  path,
  countryCode,
  hasShortPath,
  navLocale,
) => {
  const treeSructureDepth = prefix.split('/').length

  if (hasShortPath) {
    return `../${countryCode}/${navLocale}/${path}`
  }

  return `${'../'.repeat(treeSructureDepth)}${path}`
}

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line fp/no-mutating-methods
  array.sort((a, b) => {
    const A = a[key]
    const B = b[key]

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    }

    return -1
  })

  return array
}

export const indexChecker = (indexProp, mutation, carousel) => {
  const newIndex = indexProp + mutation
  const previousIndex = newIndex < 0 ? R.length(carousel) - 1 : newIndex
  const nextIndex = newIndex > R.length(carousel) - 1 ? 0 : newIndex

  return mutation === 1 ? nextIndex : previousIndex
}

export const getCareerLink = (data, countryTechnicalName) => {
  const menuItemPerCountry = R.filter(
    el => el.menuItemPerCountry !== null,
    R.map(({node}) => node, data),
  )

  const mapMenuItemPerCountry = R.map(
    e => e.menuItemPerCountry,
    menuItemPerCountry,
  )

  const flattenMenuItemPerCountry = R.flatten(mapMenuItemPerCountry)

  const careersMenuItem = R.filter(
    e => e.name.includes('Careers'),
    flattenMenuItemPerCountry,
  )

  const [currentCountryCareersMenuItem] = R.filter(
    e => e.countries[0].technicalName === countryTechnicalName,
    careersMenuItem,
  )

  const link = R.pathOr('', ['link'], currentCountryCareersMenuItem)

  return link
}

export const getNextDate = dateArray => {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth()).padStart(2, '0')
  const yy = today.getFullYear()

  const currentDate = new Date(yy, mm, dd)

  const validDateArray = R.map(date => new Date(date), dateArray)

  // eslint-disable-next-line fp/no-mutating-methods
  const sortedDates = R.sort((a, b) => {
    const distancea = Math.abs(currentDate - a)
    const distanceb = Math.abs(currentDate - b)

    return distancea - distanceb
  }, validDateArray)

  const nextDates = R.filter(d => d - currentDate > 0, sortedDates)

  const arrayFindIndex = R.map(
    date => R.equals(new Date(date), nextDates[0]),
    dateArray,
  )

  const index = R.indexOf(true, arrayFindIndex)

  return {
    index,
    date: nextDates[0],
  }
}

export const arrayByPropsAscSort = (props, arr) =>
  R.sortWith([R.ascend(R.prop(props[0])), R.ascend(R.prop(props[1]))], arr)

export const arrayByPropsDscSort = (props, arr) =>
  R.sortWith([R.descend(R.prop(props[0])), R.descend(R.prop(props[1]))], arr)

export const countSlashes = str => {
  const regex = /\//g
  const matches = str.match(regex)

  return matches ? matches.length : 0
}

export const groupUrl = url => {
  const regex = /^\/?((\/\w+)*\/)([\w\-.]+[^#?\s]+)??$/gm
  const regexToRemoveSlug = url.includes('/worldwide/')
    ? /^\/worldwide(\/.*)/gm
    : /^\/[^/]+\/[^/]+(\/.+[^/])?\/?$/gm
  const regexToRemoveCountryAndLocale = /^.*?(?=\/[^/]*$)/gm
  const str = url

  const [, noSlugPath] = regexToRemoveSlug.exec(str)
  const longPrefix = regexToRemoveCountryAndLocale.exec(noSlugPath)

  let m

  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++
    }

    const groupMapping = m.map((group, index) => {
      const createKeyName = () => {
        if (index === 0) {
          return 'path'
        }
        if (index === 1) {
          return 'slugPrefix'
        }
        if (index === 2) {
          return 'parent'
        }
        if (index === 3) {
          return 'slug'
        }

        return false
      }
      const groups = {
        [createKeyName()]: group,
      }

      return groups
    })

    const finalObj = {}

    groupMapping.forEach((el, index) =>
      Object.assign(finalObj, groupMapping[index]),
    )

    Object.assign(finalObj, {longPrefix: longPrefix[0]})

    return finalObj
  }

  return false
}

export const getCommonItems = (array1, array2, key) =>
  array1.filter(
    item1 => array2 && array2?.some(item2 => item1[key] === item2[key]),
  )

export const hasProtocol = link =>
  link.includes('https://') || link.includes('http://')

export function clearHtmlAndshrink(content = '', shrink = 500) {
  const cleanedContent = content
    .replace(/(<([^>]+)>)/gi, '') // remove HTML tags
    .replace(/\((.*?)\)|[[*>]|[\]]/g, '') // remove markdown template characters;

  if (cleanedContent.length > shrink) {
    return `${cleanedContent.substring(0, shrink)}...`
  } else {
    return cleanedContent
  }
}
