import * as R from 'ramda'
import {bool, node, string} from 'prop-types'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import React from 'react'

import classnames from 'classnames'
import useStyles from './styles'

const Media = ({
  data,
  isRoundImage,
  alt,
  is169Ratio,
  className,
  key,
  isFullWidth,
  hasNoStyles,
}) => {
  const classes = useStyles()

  const file = R.pathOr(' ', ['file'], data)
  const fluid = R.pathOr(null, ['fluid'], data)
  const description = R.pathOr(' ', ['description'], data)
  const title = R.pathOr(' ', ['title'], data)

  const image = getImage(data)

  const getAlt = () => {
    if (alt) {
      return alt
    }
    if (R.length(R.trim(description)) > 0) {
      return description
    }
    if (R.length(R.trim(title)) > 0) {
      return title
    }

    return ''
  }

  return (
    <>
      {image && (
        <GatsbyImage
          key={key}
          image={image}
          alt={getAlt()}
          className={classnames(classes.image, className, {
            [classes.svgImage]: file.contentType === 'image/svg+xml',
            [classes.roundImage]: isRoundImage,
            [classes.aspectRatio169]: is169Ratio,
          })}
        />
      )}

      {file && !fluid && !image && file.contentType === 'video/mp4' && (
        <video
          type={file.contentType}
          src={file.url}
          loop
          controls
          playsInline
          className={classes.videoAsset}
        />
      )}
      {file && !fluid && !image && file.contentType === 'image/svg+xml' && (
        <img
          key={key}
          src={file.url}
          alt={getAlt()}
          className={classnames(classes.svgImage, className)}
          loading="lazy"
        />
      )}
      {file &&
        !fluid &&
        !image &&
        (file.contentType === 'image/jpeg' ||
          file.contentType === 'image/png') && (
          <img
            key={key}
            src={file.url}
            alt={getAlt()}
            className={classnames(
              (isRoundImage && classes.roundImage) ||
                (!hasNoStyles && classes.jpegOrPngImage) ||
                null,
              is169Ratio && classes.aspectRatio169,
              isFullWidth && classes.isFullWidth,
            )}
            loading="lazy"
          />
        )}
    </>
  )
}

Media.propTypes = {
  alt: string,
  data: node,
  is169Ratio: bool,
  isRoundImage: bool,
}
Media.defaultProps = {
  alt: '',
  data: null,
  is169Ratio: false,
  isRoundImage: false,
}

export default Media
