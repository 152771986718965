import * as R from 'ramda'
import {MuiThemeProvider} from '@material-ui/core/styles'
import loadable from '@loadable/component'
import React, {useEffect, useState} from 'react'

import {getServices} from 'helpers/services'
import defaultTheme from 'config/theme'
import Footer from 'components/Layout/FooterChina'
import Header from 'components/Layout/HeaderChina'
import palette1 from 'config/palette1'
import useAosInit from 'hooks/useAosInit'
import useIsPartnersPage from 'hooks/useIsPartnersPage'
import useMountComponent from 'hooks/useMountComponent'

import useStyles from './styles'

const LayoutKeyrusChina = props => {
  const Popper = loadable(() => import('components/UI/Popper'))
  const ScrollTop = loadable(() => import('components/UI/ScrollTopButton'))
  const isPartnersPage = useIsPartnersPage()
  const {children, data, pageHasNoNavigation, layoutData, pageContext} = props
  const countryData = R.path(['contentfulCountry'], data)

  const services = R.pathOr(null, ['contentfulCountry', 'services'], data)

  const topLevelServices =
    services && R.pathOr(null, ['topLevelServices'], services)

  const countryServices =
    topLevelServices && getServices(data, topLevelServices)

  const classes = useStyles()

  const [showLayout, setShowLayout] = useState(false)

  const isLayoutMounted = useMountComponent()
  const showNav =
    process.env.GATSBY_IN_SITE_MAINTENANCE === 'false' && !pageHasNoNavigation

  const handleLayoutDisplay = bool =>
    bool ? {visibility: 'visible'} : {visibility: 'hidden'}

  useEffect(() => {
    setShowLayout(isLayoutMounted)
  }, [isLayoutMounted])

  useAosInit(showLayout)

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <MuiThemeProvider theme={palette1}>
        <div
          style={handleLayoutDisplay(showLayout)}
          className={showNav && classes.wrapper}
        >
          {showNav && (
            <Header
              pageContext={pageContext}
              countryData={countryData}
              data={layoutData}
              // popperData={popperData}
              // setPopperData={setPopperData}
              countryServices={countryServices}
            />
          )}
          {children}
          {showNav && (
            <Footer
              pageContext={pageContext}
              countryData={countryData}
              data={layoutData}
            />
          )}
          {!isPartnersPage && <ScrollTop />}
        </div>
      </MuiThemeProvider>
    </MuiThemeProvider>
  )
}

export default LayoutKeyrusChina
