import React from 'react'
import ReactDOM from 'react-dom'

import LayoutChina from 'components/Layout/LayoutChina'

import './src/styles/global.css'
import './static/fonts/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {useLayoutData} from './src/hooks/useLayoutData'

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export const shouldUpdateScroll = () => {
  if (window.location.href.indexOf('#') > -1) {
    return true
  }

  return [0, 0]
}

const MyPageElementWrapper = ({element, props}) => {
  const {pageContext, pageResources} = props
  const pageIs404 = pageResources.page.path.includes('404')
  const pageIsDocument = pageContext.hasNoNavigation
  const pageHasNoNavigation = pageIs404 || pageIsDocument

  const layoutData = useLayoutData()

  return (
    <LayoutChina
      pageHasNoNavigation={pageHasNoNavigation}
      layoutData={layoutData}
      {...props}
    >
      {element}
    </LayoutChina>
  )
}

export const wrapPageElement = ({element, props}) => (
  <MyPageElementWrapper element={element} props={props} />
)
