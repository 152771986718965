import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, breakpoints, spacing, transition}) => ({
  slideSocials: {
    position: 'relative',
    display: 'flex',
    '& > a': {
      opacity: '0.7',
      paddingTop: spacing(0.75),
      paddingRight: spacing(2),
      transition: transition.animation('opacity'),
      '&:hover': {
        background: 'transparent',
        opacity: 1,
      },
    },
    '& > a:nth-last-of-type(1)': {
      paddingRight: 0,
    },
    '& img': {
      width: 24,
      height: '100%',
    },
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  marginTop: {
    marginTop: spacing(7.5),
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
    },
  },
  isCentered: {
    justifyContent: 'center',
  },

  image: {
    width: 24,
    height: '100%',
  },

  isLarge: {
    width: `${spacing(4)}px !important`,
  },

  primaryColor: {
    '& img': {
      filter: palette.filtered.primary,
    },
  },
  borderRadius: {
    '& img': {
      borderRadius: spacing(1),
    },
  },
  spaced: {
    '& > a': {
      paddingRight: spacing(5),
    },
  },
  instagramHover: {
    '&:hover': {
      filter: palette.filtered.instagram,
    },
  },
  linkedinHover: {
    '&:hover': {
      filter: palette.filtered.linkedIn,
    },
  },
}))

export default useStyles
