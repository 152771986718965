import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, layout, spacing}) => ({
  svgImage: {
    height: 'auto',
    width: '100%',
    maxHeight: layout.mainCarousel.image.maxHeight,
    objectFit: 'contain',
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
    },
  },
  jpegOrPngImage: {
    height: 'auto',
    width: '100%',
    borderRadius: spacing(5, 1),
  },
  roundImage: {
    height: 140,
    width: 140,
    borderRadius: spacing(10),
  },
  videoAsset: {
    height: 'auto',
    width: '100%',
    [breakpoints.up('sm')]: {
      maxHeight: '80vh',
    },
  },
  aspectRatio169: {
    aspectRatio: 16 / 9,
  },
  fullWidth: {
    width: '100%',
  },
}))

export default useStyles
