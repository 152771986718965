import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, layout, palette, spacing}) => ({
  renderDeskopNav: {
    [breakpoints.down('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
    [breakpoints.up('md')]: {
      display: 'block',
      visibility: 'visible',
    },
  },

  renderMobileNav: {
    width: '90% !important',
    [breakpoints.up('md')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  navigationItem: {
    [breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: spacing(4),
      marginRight: spacing(0),
    },
  },
  container: {
    height: layout.header.height,
    position: 'fixed!important',
    top: 0,
    left: 0,
    borderBottom: `1px solid ${palette.background.gray}`,
    fontFamily: 'CamptonLight',
    backgroundColor: palette.background.default,
    zIndex: 20,
    width: '100vw',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > div': {
      maxWidth: 1300,
      width: '95%',
    },
    [breakpoints.down('sm')]: {
      height: layout.header.heightMobile,
    },
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%!important',
  },
  logoKeyrus: {
    marginTop: 6,
    paddingRight: spacing(1.8),
    '& > img': {
      width: layout.logo.width,
      height: '100%',
      [breakpoints.down('sm')]: {
        width: layout.logo.mobile.width,
        height: '100%',
      },
    },
  },
  logoKeyrusModal: {
    marginTop: spacing(4),
    marginBottom: spacing(1.5),
  },
  bridgeHover: {
    position: 'relative',
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
    },
  },
  bridgeHoverShape: {
    // '& > button::before': {
    //   cursor: 'default',
    //   top: 53,
    //   width: '122%',
    //   height: 23,
    //   content: "''",
    //   zIndex: zIndex.header,
    //   position: 'absolute',
    // },
  },
  hamburger: {
    all: 'inherit',
    display: 'grid',
    width: layout.header.hamburger.width,
    height: layout.header.hamburger.height,
    marginRight: 0,
    cursor: 'default',
    margin: 'auto',
    '&::before': {
      width: '1em',
      height: 2,
      content: '""',
      position: 'absolute',
      background: 'black',
      boxShadow: '0 0.5em 0 0 black, 0 1em 0 0 black',
    },
    '&:hover': {
      '&::before': {
        background: palette.primary.main,
        boxShadow: `0 0.5em 0 0 ${palette.primary.main}, 0 1em 0 0 ${palette.primary.main}`,
      },
    },
  },
  switchLanguageButton: {
    userSelect: 'none',
    cursor: 'default',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
    border: 'none',
    backgroundColor: 'transparent',
    fontFamily: 'CamptonBook',
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%',
  },
  bgModal: {
    top: '0',
    right: '0',
    position: 'absolute',
    background: '#0d1034',
    opacity: 0.4,
    width: '100vw',
    height: '100vh',
    touchAction: 'none',
  },
  modal: {
    top: '0',
    right: '0',
    maxWidth: 320,
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2.8, 0, 2.8, 2.8),
    position: 'absolute',
    background: 'white',
    alignIitems: 'flex-end',
    height: '100vh',
    [breakpoints.down('sm')]: {
      zIndex: 1,
    },
  },
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    height: layout.header.navigation.height,
    maxHeight: 100,
    fontSize: '1.13em',
    '& > button:last-of-type, & > a > button:last-of-type': {
      marginLeft: spacing(1.7),
    },
    // [breakpoints.up('md')]: {
    //   '& >li:last-of-type': {
    //     marginRight: '2em',
    //   },
    // },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      maxHeight: 'inherit',
      alignItems: 'flex-end',
      '& > button:last-of-type': {
        marginLeft: 0,
      },
    },
  },
  scrollable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '3vw',
    width: '100%',
    '& > *': {
      paddingRight: spacing(1.8),
    },
    '& > li:first-of-type': {
      marginTop: spacing(2),
    },
    '& > div:last-of-type': {
      marginBottom: spacing(5),
    },
    '& > button:last-of-type': {
      marginBottom: spacing(5),
    },
    [breakpoints.down('sm')]: {
      height: 'calc(100vh - 113px)',
      '& > button:last-of-type': {
        maxWidth: layout.header.scrollable.maxWidth,
        width: layout.header.scrollable.width,
        marginRight: spacing(1.1),
      },
      overflowY: 'scroll',
      overflowX: 'hide',
    },
  },

  buttonArrow: {
    transform: 'rotate(225deg)',
  },
}))

export default useStyles
