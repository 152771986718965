import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(
  ({layout, breakpoints, palette, transition, fontSizes, spacing}) => ({
    container: {
      backgroundColor: palette.background.greyLight,
      fontFamily: 'CamptonLight',
      position: 'relative',
      zIndex: 15,
    },
    menu: {
      padding: spacing(7.7, 0),
    },
    top: {
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column',
      },
    },
    leftSide: {
      width: '40%',
      borderRight: `1px solid ${palette.background.gray}`,
      paddingRight: spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      [breakpoints.down('sm')]: {
        display: 'block',
        width: '100%',
        borderRight: 0,
      },
    },
    leftSideTop: {
      height: 'auto',
    },
    infos: {
      margin: `${spacing(2.5)}px 0 ${spacing(4.13)}px 0`,
      lineHeight: 'normal',
      minHeight: spacing(8.25),
      [breakpoints.down('sm')]: {
        margin: `${spacing(2.5)}px 0 ${spacing(0.5)}px 0`,
        minHeight: 'none',
      },
      '& p': {margin: 0},
    },
    logoKeyrus: {
      width: 'fit-content',
      '& > img': {
        height: layout.logo.height,
        width: '100%',
      },
      [breakpoints.down('sm')]: {
        /*         width: layout.logo.mobile.width,
         */ height: layout.logo.mobile.height,
        marginBottom: spacing(2),
        '& > img': {
          marginTop: 0,
          height: layout.logo.mobile.height,
        },
      },
    },
    formControl: {
      marginTop: spacing(2.5),
      '& .MuiSelect-select.MuiSelect-select': {
        padding: `0 ${spacing(2)}!important 0 0`,
      },
    },
    outlinedInput: {
      backgroundColor: 'transparent!important',
      zIndex: '2',
      fontFamily: 'CamptonBook',
      color: palette.primary.main,
    },
    root: {
      zIndex: '2',
    },
    selectIcon: {
      marginRight: spacing(1),
      marginLeft: `-${spacing(3)}px`,
      zIndex: '0',
      color: palette.primary.main,
    },
    socialIcons: {
      display: 'flex',
      justifyContent: 'start',
      '& > *': {
        opacity: '0.7',
        transition: transition.animation('opacity'),
        '&:hover': {
          background: 'transparent',
          opacity: 1,
        },
      },
      '&:first-child': {
        paddingLeft: 0,
      },
    },
    rightSide: {
      display: 'flex',
      width: '60%',
      marginLeft: '7%',
      '& > div:nth-last-of-type(1)': {
        marginRight: 0,
      },
      [breakpoints.down('sm')]: {
        margin: `${spacing(5)}px 0 0 0`,
        width: '100%',
        '& > div:nth-last-of-type(1)': {
          marginRight: '5%',
        },
      },
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    bottomSide: {
      borderTop: `1px solid ${palette.background.gray}`,
      marginTop: spacing(5.88),
      paddingTop: spacing(3.88),
      display: 'flex',
      [breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'space-between',
      },
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
        paddingTop: spacing(5.88),
      },
    },
    copyright: {
      marginRight: spacing(4.38),
      [breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    legal: {
      '& > a': {
        marginRight: spacing(3.75),

        [breakpoints.down('sm')]: {
          margin: `auto ${spacing(1)}px`,
        },
        [breakpoints.down('xs')]: {
          margin: `${spacing(3.125)}px auto auto 0`,
        },
      },
      '& > a:hover': {
        color: palette.primary.main,
      },
    },
    navigationItemGrey: {
      '& > a ': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: fontSizes.medium,
        fontFamily: 'CamptonBook',
        color: palette.text.grey,
        textDecoration: 'none',
        border: 'none',
        background: 'transparent',
        padding: 0,
        outline: 'none',
      },
    },
    contactUsWrapper: {
      marginTop: 25,
    },
  }),
)

export default useStyles
