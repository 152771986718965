import {bool, func} from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

import useStyles from './styles'

const CloseButton = ({action, hasNoClass}) => {
  const classes = useStyles()

  return (
    <CloseIcon
      onClick={() => action(false)}
      className={!hasNoClass && classes.buttonClose}
    />
  )
}

CloseButton.propTypes = {action: func, hasNoClass: bool}
CloseButton.defaultProps = {action: null, hasNoClass: false}

export default CloseButton
