import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, fontSizes, palette, spacing}) => ({
  backLink: {
    color: palette.primary.main,
    fontWeight: 600,
    fontSize: fontSizes.description,
    fontFamily: 'CamptonSemiBold',
    textDecoration: 'none',
    textTransform: 'none',
    [breakpoints.down('sm')]: {
      visibility: 'hidden',
    },
  },
  positionRelative: {
    marginTop: spacing(4),
  },
  positionAbsolute: {
    width: 'max-content',
    position: 'absolute',
    top: spacing(3.5),
  },
  buttonArrow: {
    transform: 'rotate(225deg)',
  },
  isActive: {
    color: `${palette.primary.main} !important`,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 4,
      width: '100%',
      bottom: '-4px',
      left: 0,
      backgroundColor: palette.primary.main,
      borderRadius: spacing(0, 0.5),
      opacity: 1,
    },
  },
  isSubmodalActive: {
    color: `${palette.primary.main} !important`,
    background: palette.primary.hoverLight,
    fontFamily: 'CamptonMedium',
  },
}))

export default useStyles
