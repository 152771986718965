import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, spacing}) => ({
  searchIcon: {
    color: palette.text.primary,
    pointerEvents: 'none',
  },
  searchIconWrapper: {
    marginLeft: '0.7em',
    marginRight: spacing(2.5),
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
}))

export default useStyles
