module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"disableAutoprefixing":true,"disableMinification":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5K6PST9","includeInDevelopment":false,"timeout":2500,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"UbHhL1LGxQeMPH3gWcP9Tw","gtmPreview":"env-17"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":["cache-control: public, max-age=0, must-revalidate"],"headers":{"/*":["Cache-Control: public","Cache-Control: max-age=365000000","Cache-Control: immutable"],"/static/*":["Cache-Control: public","Cache-Control: max-age=365000000","Cache-Control: immutable"],"/static/fonts/*":["Cache-Control: public","Cache-Control: max-age=365000000","Cache-Control: immutable"]},"mergeSecurityHeaders":true,"mergeLinkHeaders":true,"mergeCachingHeaders":true,"generateMatchPathRewrites":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["CamptonBook, CamptonSemiBold, CamptonMedium, CamptonLight"],"urls":["static/fonts/fonts.css"]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#28c9ff","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
