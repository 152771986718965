/* eslint-disable no-param-reassign */
import * as R from 'ramda'

export const renderCustomServicesMenu = (services, featuredMenuItem) => {
  const arr = [
    {
      childMenu: {
        isSameMenuForAllCountries: false,
        menuItems: [],
      },
      isExternalLink: false,
      isOpenInNewWindow: false,
      isSameMenuItemForAllCountries: true,
      link: 'services',
      name: 'Services',
      title: 'Services',
      menuItemPerCountry: null,
    },
  ]

  R.map(
    el =>
      arr[0].childMenu.menuItems.push({
        isExternalLink: false,
        isOpenInNewWindow: false,
        isSameMenuItemForAllCountries: true,
        link: `services/#${el.name.replace(/ /g, '%20')}`,
        name: el.name.replace(/ /g, '%20'),
        title: el.name,
      }),
    services,
  )

  featuredMenuItem && arr[0].childMenu.menuItems.push(featuredMenuItem)

  return arr
}

export const replaceServiceMenu = (customMenu, menu) =>
  menu.map(menuItem => {
    if (menuItem.name === 'Services' || menuItem.name === 'Services-kls') {
      return {
        ...menuItem,
        childMenu: customMenu,
      }
    }

    return menuItem
  })
