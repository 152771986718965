/* eslint-disable react-hooks/rules-of-hooks */
import {string} from 'prop-types'
import React, {useState} from 'react'

import useStaticQueryImport from 'hooks/useStaticQueryImport'

import LocalSearch from './LocalSearchChina'
import SearchMenuItem from './SearchMenuItem/index'

const Search = ({country, navLocale, isMultiLang, searchPlaceholder}) => {
  const [hasFocus, setFocus] = useState(false)
  const [data] =
    process.env.GATSBY_WEBSITE === 'keyrus-china' &&
    useStaticQueryImport(
      import('../../../../keyrus-china/src/hooks/useSearchPublicUrls'),
      'useSearchPublicUrls',
    )

  return (
    <div>
      <SearchMenuItem onClick={() => setFocus(true)} />
      {data && (
        <LocalSearch
          country={country}
          navLocale={navLocale}
          show={hasFocus}
          setFocus={setFocus}
          isMultiLang={isMultiLang}
          searchPlaceholder={searchPlaceholder}
          data={data}
        />
      )}
    </div>
  )
}

Search.propTypes = {
  country: string,
  isMultiLang: string,
  navLocale: string,
  searchPlaceholder: string,
}

Search.defaultProps = {
  country: '',
  isMultiLang: '',
  navLocale: '',
  searchPlaceholder: '',
}

export default Search
