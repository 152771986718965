import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useIsMobile = () => {
  const {breakpoints} = useTheme()

  return useMediaQuery(breakpoints.down('sm'))
}

export default useIsMobile
