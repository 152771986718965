import {func} from 'prop-types'
import React from 'react'

import SearchIcon from '@material-ui/icons/Search'

import useStyles from './styles'

const SearchMenuItem = ({onClick}) => {
  const classes = useStyles()

  return (
    <button
      className={classes.searchIconWrapper}
      type="button"
      onClick={onClick}
    >
      <SearchIcon className={classes.searchIcon} />
    </button>
  )
}

SearchMenuItem.propTypes = {
  onClick: func,
}

SearchMenuItem.defaultProps = {
  onClick: null,
}

export default SearchMenuItem
