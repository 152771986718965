import * as R from 'ramda'
import {Link} from 'gatsby'
import {shape} from 'prop-types'
import classNames from 'classnames'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, {useState} from 'react'

import {collapseMenuPropTypes} from 'helpers/propTypes'
import {dynamicRouting} from 'helpers/utils'
import useIsSmallMobile from 'hooks/useIsSmallMobile'

import useStyles from './styles'

function CollapsableMenu({
  node,
  id,
  prefix,
  country,
  countryCode,
  hasShortPath,
  navLocale,
}) {
  const {menuItems, title} = node

  const mobile = useIsSmallMobile()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    if (mobile) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <div className={classes.navigation} key={id}>
      {mobile && (
        <button
          name="navigation"
          className={classes.navigationItemGrey}
          type="button"
          onClick={() => toggle()}
          aria-label="navigation"
        >
          {title}
          {mobile && (
            <ExpandMoreIcon
              className={classNames({
                [classes.arrowRevert]: isOpen,
              })}
            />
          )}
        </button>
      )}
      {menuItems && (
        <Collapse in={isOpen || !mobile}>
          <div className={classes.navigationItemBlock}>
            {R.map(subNode => {
              if (subNode.name === 'Careers') {
                return (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      R.filter(e => e.menuItemPerCountry !== null, menuItems)[0]
                        .menuItemPerCountry !== undefined &&
                      R.pathOr(
                        '',
                        [0, 'link'],
                        R.filter(
                          el => el.countries[0].technicalName === country && el,
                          R.filter(
                            e => e.menuItemPerCountry !== null,
                            menuItems,
                          )[0].menuItemPerCountry,
                        ),
                      )
                    }
                    className={classes.navigationItem}
                    key={subNode.id}
                  >
                    {subNode.title}
                  </a>
                )
              }
              if (subNode.isExternalLink) {
                return (
                  <a
                    target={subNode.isOpenInNewWindow && '_bank'}
                    href={`//${subNode.link}`}
                    className={classes.navigationItem}
                    key={subNode.id}
                  >
                    {subNode.title}
                  </a>
                )
              }

              return (
                <Link
                  to={dynamicRouting(
                    prefix,
                    subNode.link,
                    countryCode,
                    hasShortPath,
                    navLocale,
                  )}
                  className={classes.navigationItem}
                  key={subNode.id}
                >
                  {subNode.title}
                </Link>
              )
            }, menuItems)}
          </div>
        </Collapse>
      )}
    </div>
  )
}

CollapsableMenu.propTypes = collapseMenuPropTypes

CollapsableMenu.defaultPropTypes = shape({
  id: '',
  prefix: '',
  node: null,
  countryCode: '',
  hasShortPath: false,
})

export default CollapsableMenu
